import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { InView } from 'react-intersection-observer'
import SVG from 'react-inlinesvg'

import HorWrap from '@atoms/HorWrap'
import PurpleHeader from '@atoms/PurpleHeader'

import { Wrapper, Awards, SceneCurtain } from './styles.js'

const Hero = ({ data }) => {
  const [sectionInView, setSectionInView] = useState(false)

  return (
    <InView
      onChange={(inView, entry) => inView && setSectionInView(true)}
      threshold={0.25}
    >
      <Wrapper
        active={sectionInView}
        bgColor={data.bgColor.hex}
        bgImageMobile={data.bgImageMobile ? data.bgImageMobile.url : false}
        bgImage={data.bgImage ? data.bgImage.url : false}
      >
        <HorWrap>
          {data.logo ? (
            <img src={data.logo.url} />
          ) : (
            <PurpleHeader
              active={sectionInView}
              delay={3}
              color={data.textColor.hex}
            >
              {data.nameHeaderFallback}
            </PurpleHeader>
          )}
          <Awards active={sectionInView}>
            {data.awards.map(item => (
              <div key={item.name}>
                <SVG src={item.logo.url} />
              </div>
            ))}
          </Awards>
        </HorWrap>
        <SceneCurtain active={sectionInView}>
          <div></div>
          <div></div>
          <div></div>
        </SceneCurtain>
      </Wrapper>
    </InView>
  )
}

Hero.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
}

Hero.defaultProps = {
  children: null,
}

export default Hero

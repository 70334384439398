import styled, { css } from 'styled-components'

export const Wrapper = styled.section`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  width: 100%;
  padding: 0 0 10%;
  aspect-ratio: 5 / 7;
  ${({ bgImageMobile }) =>
    bgImageMobile &&
    `background: center center url(${bgImageMobile}) no-repeat;`}
  background-size: cover;
  background-color: ${({ bgColor }) => (bgColor ? bgColor : '#D9D9D9')};

  ${({ theme }) => theme.media.m} {
    aspect-ratio: 12 / 5;
    padding: 0 0 5%;
  }

  ${({ theme }) => theme.media.l} {
    aspect-ratio: 12 / 5;
    padding: 0 0 2%;
    ${({ bgImage }) =>
      bgImage && `background: center center url(${bgImage}) no-repeat;`}
    background-color: ${({ bgColor }) => (bgColor ? bgColor : '#D9D9D9')};
    background-size: cover;
  }
`
export const Awards = styled.div`
  > div {
    display: inline-block;
    margin-right: 20px;
    height: 45px;
    aspect-ratio: 1 / 1;
    transition: all 0.6s ease;

    > svg {
      width: 100%;
      height: auto;
    }

    &:nth-child(1) {
      transition-delay: 3s;
    }

    &:nth-child(2) {
      transition-delay: 3.1s;
    }

    &:nth-child(3) {
      transition-delay: 3.2s;
    }

    &:nth-child(4) {
      transition-delay: 3.3s;
    }

    ${({ active }) =>
      active
        ? css`
            opacity: 1;
            transform: translate3d(0, 0, 0);
          `
        : css`
            opacity: 0;
            transform: translate3d(0, 30px, 0);
          `}
  }

  ${({ theme }) => theme.media.m} {
    > div {
      height: 50px;
    }
  }

  ${({ theme }) => theme.media.l} {
    > div {
      height: 80px;
    }
  }
`

export const SceneCurtain = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  > div {
    overflow: hidden;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: ${({ active }) => (active ? '100%' : '0')};
    transition: height 0.8s 0.5s cubic-bezier(0.4, 0, 0.2, 1);
    pointer-events: none;
  }

  > div:nth-child(1) {
    height: 100%;
    background: white;
    opacity: ${({ active }) => (active ? '0' : '1')};
    transition: opacity 0.8s 2s ease;
  }

  > div:nth-child(2) {
    &::before,
    &::after {
      content: '';
      position: absolute;
      left: 0;
      width: 100%;
      height: 52%;
      background: white;
      transition: transform 0.8s 2.6s cubic-bezier(0.4, 0, 0.2, 1);
    }

    &::before {
      bottom: 50%;
      border-bottom: 2px solid black;
      transform: translate3d(0, ${({ active }) => (active ? '-100%' : '0')}, 0);
    }

    &::after {
      top: calc(50% - 3px);
      border-top: 2px solid black;
      transform: translate3d(0, ${({ active }) => (active ? '120%' : '0')}, 0);
    }
  }

  > div:nth-child(3) {
    &::before,
    &::after {
      content: '';
      position: absolute;
      top: 0;
      bottom: -30px;
      width: 50%;
      background: white;
      transition: transform 0.8s 1.6s cubic-bezier(0.4, 0, 0.2, 1);
    }

    &::before {
      right: 50%;
      border-right: 2px solid black;
      transform: translate3d(${({ active }) => (active ? '-101%' : '0')}, 0, 0);
    }

    &::after {
      left: calc(50% - 3px);
      border-left: 2px solid black;
      transform: translate3d(${({ active }) => (active ? '103%' : '0')}, 0, 0);
    }
  }
`

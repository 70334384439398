import React from 'react'
import Main from '@templates/Main'

import Hero from '@blocks/Hero'
import Spacer from '@blocks/Spacer'

const NotFoundPage = ({ transitionStatus }) => {
  const seoData = {
    tags: [
      {
        tagName: 'title',
        content: '404 | Page not found | Mola Creative Agency',
      },
      {
        tagName: 'meta',
        attributes: {
          property: 'og:title',
          content: '404',
        },
      },
      {
        tagName: 'meta',
        attributes: {
          name: 'twitter:title',
          content: '404',
        },
      },
      {
        tagName: 'meta',
        attributes: {
          name: 'description',
          content: 'Page not found',
        },
      },
      {
        tagName: 'meta',
        attributes: {
          property: 'og:description',
          content: 'Page not found',
        },
      },
      {
        tagName: 'meta',
        attributes: {
          name: 'twitter:description',
          content: 'Page not found',
        },
      },
      {
        tagName: 'meta',
        attributes: {
          name: 'twitter:card',
          content: 'summary',
        },
      },
      {
        tagName: 'meta',
        attributes: {
          property: 'article:modified_time',
          content: '2023-01-19T17:14:36Z',
        },
      },
      {
        tagName: 'meta',
        attributes: {
          property: 'article:published_time',
          content: '2023-01-19T17:14:36Z',
        },
      },
      {
        tagName: 'meta',
        attributes: {
          property: 'og:locale',
          content: 'en_EN',
        },
      },
      {
        tagName: 'meta',
        attributes: {
          property: 'og:type',
          content: 'article',
        },
      },
      {
        tagName: 'meta',
        attributes: {
          property: 'og:site_name',
          content: 'Mola',
        },
      },
      {
        tagName: 'meta',
        attributes: {
          property: 'og:image',
          content:
            'https://www.datocms-assets.com/44527/1674148558-fb-share.jpg?w=1000&fit=max&fm=jpg',
        },
      },
      {
        tagName: 'meta',
        attributes: {
          name: 'twitter:image',
          content:
            'https://www.datocms-assets.com/44527/1674148558-fb-share.jpg?w=1000&fit=max&fm=jpg',
        },
      },
      {
        tagName: 'meta',
        attributes: {
          property: 'og:image:width',
          content: 1600,
        },
      },
      {
        tagName: 'meta',
        attributes: {
          property: 'og:image:height',
          content: 600,
        },
      },
    ],
  }

  return (
    <Main
      seo={seoData}
      transitionStatus={transitionStatus}
      enterColor="#4100F5"
    >
      <Hero
        data={{
          id: 'error404',
          nameHeaderFallback: 'Page not found',

          textColor: {
            hex: '#4100F5',
          },
          bgColor: {
            hex: '#cccccc',
          },
          awards: [],
        }}
      />
      <Spacer size="xl" />
    </Main>
  )
}

export default NotFoundPage
